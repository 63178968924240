import './StartUsingShapya.css';
import '../../styles/shapyaMaxWidth.css';

import ChangeYourStatus from './img/change-your-status.png';
import ChooseYourRole from './img/choose-your-role.png';
import OrdersResume from './img/orders-resume.png';
import OrdersResumeMd from './img/orders-resume-md.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StartUsingShapya = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="startUsingShapyaMainGridLg">
        <div className="startUsingShapyaTitle">
          {t('startUsingShapya.titleP1')}
          <span className="startUsingShapyaTitleRed">
            {t('startUsingShapya.titleP2')}
          </span>
          {t('startUsingShapya.titleP3')}
        </div>
        <div className="startUsingShapyaDescription">
          {t('startUsingShapya.descriptionP1')}
          <span className="startUsingShapyaDescriptionBold">
            {t('startUsingShapya.descriptionP2')}
          </span>
          {t('startUsingShapya.descriptionP3')}
          <span className="startUsingShapyaDescriptionBold">
            {t('startUsingShapya.descriptionP4')}
          </span>
        </div>
        <div className="startUsingShapyaAppScreensDiv">
          <img
            src={ChooseYourRole}
            alt="Choose Your Role"
            className="chooseYourRoleImg"
          />
          <img
            src={ChangeYourStatus}
            alt="Change Your Status"
            className="changeYourStatusImg"
          />
          <img
            src={OrdersResume}
            alt="Orders Resume"
            className="ordersResumeImg"
          />
        </div>
        <div className="nowPartOfShapyaTitle">{t('nowPartOfShapya.title')}</div>
      </div>

      <div className="startUsingShapyaMainGridMd">
        <div>
          <div>
            <img
              src={ChooseYourRole}
              alt="Choose Your Role"
              className="chooseYourRoleImg"
            />
          </div>
          <div>
            <img
              src={OrdersResumeMd}
              alt="Orders Resume"
              className="ordersResumeImg"
            />
          </div>
        </div>
        <div>
          <div className="startUsingShapyaTitle">
            {t('startUsingShapya.titleP1')}
            <span className="startUsingShapyaTitleRed">
              {t('startUsingShapya.titleP2')}
            </span>
            {t('startUsingShapya.titleP3')}
          </div>
          <div className="startUsingShapyaDescription">
            {t('startUsingShapya.descriptionP1')}
            <span className="startUsingShapyaDescriptionBold">
              {t('startUsingShapya.descriptionP2')}
            </span>
            {t('startUsingShapya.descriptionP3')}
            <span className="startUsingShapyaDescriptionBold">
              {t('startUsingShapya.descriptionP4')}
            </span>
          </div>
          <div>
            <img
              src={ChangeYourStatus}
              alt="Change Your Status"
              className="changeYourStatusImg"
            />
          </div>
          <div className="nowPartOfShapyaTitle">
            {t('nowPartOfShapya.title')}
          </div>
        </div>
      </div>

      <div className="startUsingShapyaMainGridSm">
        <div>
          <img
            src={ChooseYourRole}
            alt="Choose Your Role"
            className="chooseYourRoleImg"
          />
        </div>

        <div className="startUsingShapyaTitle">
          {t('startUsingShapya.titleP1')}
          <span className="startUsingShapyaTitleRed">
            {t('startUsingShapya.titleP2')}
          </span>
          {t('startUsingShapya.titleP3')}
        </div>
        <div className="startUsingShapyaDescription">
          {t('startUsingShapya.descriptionP1')}
          <span className="startUsingShapyaDescriptionBold">
            {t('startUsingShapya.descriptionP2')}
          </span>
          {t('startUsingShapya.descriptionP3')}
          <span className="startUsingShapyaDescriptionBold">
            {t('startUsingShapya.descriptionP4')}
          </span>
        </div>

        <div>
          <img
            src={ChangeYourStatus}
            alt="Change Your Status"
            className="changeYourStatusImg"
          />
        </div>

        <div>
          <img
            src={OrdersResumeMd}
            alt="Orders Resume"
            className="ordersResumeImg"
          />
        </div>

        <div className="nowPartOfShapyaTitle">{t('nowPartOfShapya.title')}</div>
      </div>
    </>
  );
};

export default StartUsingShapya;
