import './YouHaveBeenCorrectlyAdded.css';
import '../../styles/shapyaMaxWidth.css';

import CheckCircle from './img/check-circle.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

const YouHaveBeenCorrectlyAdded = () => {
  const { t } = useTranslation();

  return (
    <div className="youHaveBeenCorrectlyAddedMainGrid">
      <div className="youHaveBeenCorrectlyAddedCard">
        <div className="checkCircleImg">
          <img src={CheckCircle} alt="Check Circle" width="117" height="117" />
        </div>
        <div className="correctlyAddedTitle">
          {t('shapyerFollowSteps.correctlyAdded.title')}
        </div>
        <div className="correctlyAddedSubtitle">
          {t('shapyerFollowSteps.correctlyAdded.subtitleP1')}
          <span className="correctlyAddedSubtitleBold">
            {t('shapyerFollowSteps.correctlyAdded.subtitleP2')}
          </span>
          {t('shapyerFollowSteps.correctlyAdded.subtitleP3')}
        </div>
      </div>
    </div>
  );
};

export default YouHaveBeenCorrectlyAdded;
