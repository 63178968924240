import '../i18n';

import { BackToTop } from 'material-ui-back-to-top';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import NavbarWhite from '../components/navbar/NavbarWhite';
import React from 'react';
import ShapyerFollowStepsBody from '../components/shapyerFollowSteps/ShapyerFollowStepsBody';
import { useTranslation } from 'react-i18next';

const ShapyerFollowSteps = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('seo.page.shapyerFollowSteps.title')}</title>
      </Helmet>
      <BackToTop size="small" buttonPosition={{ bottom: 20, right: 20 }} />
      <NavbarWhite />
      <ShapyerFollowStepsBody />
      <Footer />
    </React.Fragment>
  );
};

export default ShapyerFollowSteps;
