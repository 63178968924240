import './TapTheEmail.css';
import '../../styles/shapyaMaxWidth.css';

import React from 'react';
import TapTheEmailImg from './img/tap-the-email.png';
import { useTranslation } from 'react-i18next';

const TapTheEmail = () => {
  const { t } = useTranslation();

  return (
    <div className="tapTheEmailMainGrid">
      <div>
        <img
          src={TapTheEmailImg}
          alt="Tap The Email"
          className="tapTheEmailImg"
        />
      </div>
      <div className="tapTheEmailTitle">
        {t('02tapTheEmail.titleP1')}
        <span className="tapTheEmailTitleRed">
          {t('02tapTheEmail.titleP2')}
        </span>
        {t('02tapTheEmail.titleP3')}
      </div>
    </div>
  );
};

export default TapTheEmail;
