import './AcceptTermsConditions.css';
import '../../styles/shapyaMaxWidth.css';

import AcceptTermsConditionsImg from './img/accept-terms-conditions.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AcceptTermsConditions = () => {
  const { t } = useTranslation();

  return (
    <div className="acceptTermsConditionsMainGrid">
      <div className="acceptTermsConditionsTitleDescription">
        <p className="acceptTermsConditionsTitle">
          {t('05acceptTermsConditions.titleP1')}
          <span className="acceptTermsConditionsTitleRed">
            {t('05acceptTermsConditions.titleP2')}
          </span>
          {t('05acceptTermsConditions.titleP3')}
        </p>
        <p className="acceptTermsConditionsDescription acceptTermsConditionsDescriptionSmUp">
          {t('05acceptTermsConditions.description')}
        </p>
      </div>
      <div>
        <img
          src={AcceptTermsConditionsImg}
          alt="Accept Terms Conditions"
          className="acceptTermsConditionsImg"
        />
      </div>
      <div className="acceptTermsConditionsTitleDescription acceptTermsConditionsDescriptionSm">
        <p className="acceptTermsConditionsDescription">
          {t('05acceptTermsConditions.description')}
        </p>
      </div>
    </div>
  );
};

export default AcceptTermsConditions;
