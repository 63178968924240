import './EnterYourData.css';
import '../../styles/shapyaMaxWidth.css';

import EnterYourDataImg from './img/enter-your-data.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EnterYourData = () => {
  const { t } = useTranslation();

  return (
    <div className="enterYourDataMainGrid">
      <div>
        <img
          src={EnterYourDataImg}
          alt="Enter Your Data"
          className="enterYourDataImg"
        />
      </div>
      <div className="enterYourDataTitle">
        <p>{t('04enterYourData.title')}</p>
        <p className="enterYourDataSubtitle">{t('04enterYourData.subtitle')}</p>
        <p className="enterYourDataDescription">
          {t('04enterYourData.descriptionP1')}
          <span className="enterYourDataDescriptionBold">
            {t('04enterYourData.descriptionP2')}
          </span>
          {t('04enterYourData.descriptionP3')}
        </p>
      </div>
    </div>
  );
};

export default EnterYourData;
