import './SelectRegisterHere.css';
import '../../styles/shapyaMaxWidth.css';

import React from 'react';
import SelectRegisterHereImg from './img/select-register-here.png';
import { useTranslation } from 'react-i18next';

const SelectRegisterHere = () => {
  const { t } = useTranslation();

  return (
    <div className="selectRegisterHereMainGrid">
      <div className="selectRegisterHereTitle">
        {t('03tapTheEmail.titleP1')}
        <span className="selectRegisterHereTitleRed">
          {t('03tapTheEmail.titleP2')}
        </span>
      </div>
      <div>
        <img
          src={SelectRegisterHereImg}
          alt="Select Register Here"
          className="selectRegisterHereImg"
        />
      </div>
    </div>
  );
};

export default SelectRegisterHere;
