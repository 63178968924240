import './DownloadShapyaApp.css';
import '../../styles/shapyaMaxWidth.css';

import AppStore from './img/appstore.png';
import GooglePlay from './img/googleplay.png';
import React from 'react';
import ShapyaShopping from './img/shapya-shopping.png';
import { useTranslation } from 'react-i18next';

const DownloadShapyaApp = () => {
  const { t } = useTranslation();

  return (
    <div className="downloadShapyaAppMainGrid">
      <div className="downloadShapyaAppTitle">
        {t('01downloadShapyaApp.titleP1')}
        <span className="downloadShapyaAppTitleRed">
          {t('01downloadShapyaApp.titleP2')}
        </span>
        {t('01downloadShapyaApp.titleP3')}
      </div>
      <div className="downloadShapyaAppSideB">
        <div>
          <a
            href="https://play.google.com/store/apps/details?id=com.shapya.app"
            target="_blank"
          >
            <img src={GooglePlay} alt="Google Play" className="googlePlayImg" />
          </a>
          <a href="https://apps.apple.com/us/app/id1522176109" target="_blank">
            <img src={AppStore} alt="App Store" className="appStoreImg" />
          </a>
        </div>
        <div>
          <img
            src={ShapyaShopping}
            alt="ShapYa Shopping and Delivery"
            className="shapyaShoppingImg"
          />
        </div>
      </div>
    </div>
  );
};

export default DownloadShapyaApp;
