import './ChooseShapyerOption.css';
import '../../styles/shapyaMaxWidth.css';

import ChooseShapyerOptionImg from './img/choose-shapyer-option.png';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ChooseShapyerOption = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="chooseShapyerOptionMainGrid">
        <div>
          <img
            src={ChooseShapyerOptionImg}
            alt="Choose Shapyer Option"
            className="chooseShapyerOptionImg"
          />
        </div>
        <div className="chooseShapyerOptionTitle">
          {t('06chooseShapyerOption.titleP1')}
          <span className="chooseShapyerOptionTitleRed">
            {t('06chooseShapyerOption.titleP2')}
          </span>
          {t('06chooseShapyerOption.titleP3')}
        </div>
      </div>
      <div className="youAreAllSetUpDiv">
        <div className="youAreAllSetUp">{t('06youAreAllSetUp.title')}</div>
      </div>
    </div>
  );
};

export default ChooseShapyerOption;
