import './ShapyerFollowSteps.css';
import '../styles/shapyaMaxWidth.css';

import AcceptTermsConditions from './05acceptTermsConditions/AcceptTermsConditions';
import ChooseShapyerOption from './06chooseShapyerOption/ChooseShapyerOption';
import DownloadShapyaApp from './01downloadShapyaApp/DownloadShapyaApp';
import EnterYourData from './04enterYourData/EnterYourData';
import LearnMoreSuite from '../indexbody/learnMoreSuite/LearnMoreSuite';
import React from 'react';
import SelectRegisterHere from './03selectRegisterHere/SelectRegisterHere';
import StartUsingShapya from './startUsingShapya/StartUsingShapya';
import TapTheEmail from './02tapTheEmail/TapTheEmail';
import YouHaveBeenCorrectlyAdded from './youHaveBeenCorrectlyAdded/YouHaveBeenCorrectlyAdded';

const ShapyerFollowStepsBody = () => {
  return (
    <div className="shapyaMaxWidth">
      <YouHaveBeenCorrectlyAdded />
      <DownloadShapyaApp />
      <TapTheEmail />
      <SelectRegisterHere />
      <EnterYourData />
      <AcceptTermsConditions />
      <ChooseShapyerOption />
      <StartUsingShapya />
      <LearnMoreSuite />
    </div>
  );
};

export default ShapyerFollowStepsBody;
